
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Form,
  Modal,
  Tab,
  Table,
  TabPane
} from 'semantic-ui-react';
import { monthStrAbbr } from '../../../utils/formUtils';
import { getBudgetDetails, getBudgetDetailsTotals, getDatesAndYears, limitDigits } from '../../../utils/projectPlanningUtils';

const getInputStyle = ({ isCutoffDisabled, isCompanyCutoffDisabled, isDisabled, isUpdateActualDisabled }, isBudget = false, isAffectedByCompanyCutoff = false) => {
  if (isBudget) return { padding: 0, background: '' }
  return { padding: 0, background: isCutoffDisabled || (isAffectedByCompanyCutoff && isCompanyCutoffDisabled) || (isUpdateActualDisabled && isAffectedByCompanyCutoff) ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' }
}

const getTableCellStyle = ({ isCutoffDisabled, isCompanyCutoffDisabled, isDisabled, isUpdateActualDisabled }, isBudget = false, isAffectedByCompanyCutoff = false) => {
  if (isBudget) return { background: '' }
  return { background: isCutoffDisabled || (isAffectedByCompanyCutoff && isCompanyCutoffDisabled) || (isUpdateActualDisabled && isAffectedByCompanyCutoff) ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' }
}

const DetailsPane = ({ figures, storeDetails, project_type, year, isReadOnly, setValues, theOneRing, isBudget, project_status }) => {
  const details = getBudgetDetails(figures, storeDetails, project_type)
  // const totals = getBudgetDetailsTotals(details)
  // console.log('project_type', project_type);
  // console.log('details', details);
  // console.log('totals', totals);


  // BUG FIX AEP-4074 FIX - - - 
  // https://avvale-support.atlassian.net/browse/AEP-4074

  // Verifica se utilizzare actual_forecast_details.details o budget_details in base alla props isBudget
  const detailsToUse = details.map((item) => {
    // Differenzia logica in base a 'isBudget'
    if (!isBudget) {
      const ringStatus = theOneRing(item.year, item.month);
      // console.log(storeDetails);
      return ringStatus.isCutoffDisabled || ringStatus.isDisabled
        ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month) || item
        : item;
    } else {
      return item
    }
  });


  // Calcolo dei totali basato sui dettagli scelti
  const totals = getBudgetDetailsTotals(detailsToUse);
  console.log("DetailsPane ~ totals:", totals)
  // const totalsMarginOnly = getBudgetDetailsTotals(details);
  // BUG FIX AEP-4074 FIX - - - 

  // BUG FIX Margin
  const totalRevenues = detailsToUse.reduce((acc, item) => acc + item.total_revenues, 0);
  const totalCosts = detailsToUse.reduce((acc, item) => acc + item.total_cost, 0);
  const margin = totalRevenues > 0 ? (totalRevenues - totalCosts).toFixed(2) : (0).toFixed(2);
  const marginPercentage = totalRevenues > 0 ? ((totalRevenues - totalCosts) / totalRevenues * 100).toFixed(2) : (0).toFixed(2);
  // BUG FIX Margin

  // BUG FIX. Dati storici nel mese in corso: se lo stato è actual o saved vengono mostrati i dati presenti a DB.
  const statusActualOrSaved = (project_status === 'actual' || project_status === 'saved');
  // console.log("DetailsPane ~ project_status:", project_status)
  // console.log("DetailsPane ~ statusActualOrSaved:", statusActualOrSaved)

  return (
    <TabPane>
      <Table celled definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Total</Table.HeaderCell>
            {monthStrAbbr.map((monthName, monthIndex) => (<Table.HeaderCell key={monthIndex}>{monthName}</Table.HeaderCell>))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <Table.Row>
            <Table.Cell>Days</Table.Cell>
            <Table.Cell>{totals.days.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData ? cellData.days.toFixed(2) : <></>}
                </Table.Cell>
              )
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Total Revenues</Table.Cell>
            <Table.Cell>{(totals.totalRevenues || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled // ! fix total revenues non editabile || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData ? cellData.total_revenues.toFixed(2) : <></>}
                </Table.Cell>
              )
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Revenues</Table.Cell>
            <Table.Cell>{totals.revenues.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {project_type === "Time Material" ? (
                    cellData ? cellData.revenues.toFixed(2) : <></>
                  ) : (
                    <input
                      style={getInputStyle(theOneRing(cellData.year, cellData.month), isBudget)}
                      disabled={theOneRing(cellData.year, cellData.month).isDisabled || isReadOnly}
                      type="number"
                      value={cellData.revenues}
                      onChange={(event) => {
                        event.persist();
                        if (event.target !== null && event.target !== undefined) {
                          setValues(cellData.year, cellData.month, {
                            revenues: limitDigits(event.target.value)
                          });
                        }
                      }}
                    />
                  )}
                </Table.Cell>
              );
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Billable Expenses</Table.Cell>
            <Table.Cell>{totals.billableExpenses.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCompanyCutoffDisabled || theOneRing(item.year, item.month).isDisabled || theOneRing(item.year, item.month).isUpdateActualDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget, true)} key={index}>
                  <input
                    style={getInputStyle(theOneRing(cellData.year, cellData.month), isBudget, true)}
                    disabled={
                      theOneRing(cellData.year, cellData.month).isDisabled
                      || theOneRing(cellData.year, cellData.month).isCompanyCutoffDisabled
                      || theOneRing(cellData.year, cellData.month).isUpdateActualDisabled
                      || isReadOnly}
                    type="number"
                    value={cellData.billable_expenses}
                    onChange={(event) => {
                      event.persist();
                      if (event.target !== null && event.target !== undefined) {
                        setValues(cellData.year, cellData.month, {
                          billable_expenses: limitDigits(event.target.value)
                        });
                      }
                    }}
                  />
                </Table.Cell>
              );
            })}
          </Table.Row>

          {/* FIX 70 Campo total cost mancante: il campo che fa la somma di cost + expenses non è presente nella sezione Costs & Revenues come da analisi. */}
          <Table.Row>
            <Table.Cell>Total Costs</Table.Cell>
            <Table.Cell>{totals.totalCosts.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData ? cellData.total_cost.toFixed(2) : <></>}
                </Table.Cell>
              )
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Costs</Table.Cell>
            <Table.Cell>{totals.costs.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData ? cellData.cost.toFixed(2) : <></>}
                </Table.Cell>
              )
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Expenses</Table.Cell>
            <Table.Cell>{totals.expenses.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCompanyCutoffDisabled || theOneRing(item.year, item.month).isDisabled || theOneRing(item.year, item.month).isUpdateActualDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget, true)} key={index}>
                  <input
                    style={getInputStyle(theOneRing(cellData.year, cellData.month), isBudget, true)}
                    disabled={
                      theOneRing(cellData.year, cellData.month).isDisabled
                      || theOneRing(cellData.year, cellData.month).isCompanyCutoffDisabled
                      || theOneRing(cellData.year, cellData.month).isUpdateActualDisabled
                      || isReadOnly}
                    type="number"
                    value={cellData.expenses}
                    onChange={(event) => {
                      event.persist();
                      if (event.target !== null && event.target !== undefined) {
                        setValues(cellData.year, cellData.month, {
                          expenses: limitDigits(event.target.value)
                        });
                      }
                    }}
                  />
                </Table.Cell>
              );
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Delta Revenues</Table.Cell>
            <Table.Cell>{totals.deltaRevenues.toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              // Definizione della logica per usare item o storeDetails
              const cellData = theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(cellData.year, cellData.month), isBudget)} key={index}>
                  <input
                    style={getInputStyle(theOneRing(cellData.year, cellData.month), isBudget)}
                    disabled={theOneRing(cellData.year, cellData.month).isDisabled || isReadOnly}
                    type="number"
                    value={cellData.delta_adj}
                    onChange={(event) => {
                      event.persist();
                      if (event.target !== null && event.target !== undefined) {
                        setValues(cellData.year, cellData.month, {
                          delta_adj: limitDigits(event.target.value)
                        });
                      }
                    }}
                  />
                </Table.Cell>
              );
            })}
          </Table.Row>

          <Table.Row>
            <Table.Cell>Delta Billable Expenses</Table.Cell>
            <Table.Cell>{(totals.deltaBillableExpenses || 0).toFixed(2)}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              // Definizione della logica per usare item o storeDetails
              const cellData = theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(cellData.year, cellData.month), isBudget)} key={index}>
                  <input
                    style={getInputStyle(theOneRing(cellData.year, cellData.month), isBudget)}
                    disabled={theOneRing(cellData.year, cellData.month).isDisabled || isReadOnly}
                    type="number"
                    value={cellData.delta_bill_exp}
                    onChange={(event) => {
                      event.persist();
                      if (event.target !== null && event.target !== undefined) {
                        setValues(cellData.year, cellData.month, {
                          delta_bill_exp: limitDigits(event.target.value)
                        });
                      }
                    }}
                  />
                </Table.Cell>
              );
            })}
          </Table.Row>


          {/* BUG FIX Margin */}
          <Table.Row>
            <Table.Cell>Margin</Table.Cell>
            <Table.Cell>{margin}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData && cellData.total_revenues > 0 ? (cellData.total_revenues - cellData.total_cost).toFixed(2) : (0).toFixed(2)}
                </Table.Cell>
              )
            })}
          </Table.Row>
          <Table.Row>
            <Table.Cell>Margin %</Table.Cell>
            <Table.Cell>{marginPercentage}</Table.Cell>
            {details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
              const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled || (statusActualOrSaved && theOneRing(item.year, item.month).isUpdateActualDisabled)
                ? storeDetails.find(detail => detail.year === item.year && detail.month === item.month)
                : item;
              return (
                <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month), isBudget)} key={index}>
                  {cellData && cellData.total_revenues > 0 ? ((cellData.total_revenues - cellData.total_cost) / cellData.total_revenues * 100).toFixed(2) : (0).toFixed(2)}
                </Table.Cell>
              )
            })}
          </Table.Row>
          {/* BUG FIX Margin */}
        </Table.Body>
      </Table>
    </TabPane>
  )
}

function NextCostsAndRevenuesT2Modal(props) {
  const { isOpen, onClose, budget, setValues, actual_forecast_figures, budget_figures, actual_forecast_details, budget_details, isReadOnly, theOneRing } = props

  if (actual_forecast_details === undefined || actual_forecast_details.details === undefined || budget_details === undefined || budget_details.details === undefined) {
    return null;
  }

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(budget)

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeInnerPane, setActiveInnerPane] = useState(0);

  const menuItemClassActual = activeInnerPane === 0 ? 'menu-item-active' : 'menu-item'
  const menuItemClassBudget = activeInnerPane !== 0 ? 'menu-item-active' : 'menu-item'

  const onChangeInnerTab = (_event, data) => {
    setActiveInnerPane(data.activeIndex)
    // console.log('onChangeInnerTab', data.activeIndex);
  }

  const onOuterTabChange = (_event, data) => {
    setActiveIndex(data.activeIndex)
    // console.log('onOuterTabChange', data.activeIndex);
  }



  return (
    <Modal
      size="large"
      open={isOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon
      dimmer="blurring"
    >
      <Modal.Header>Costs & Revenues</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', float: 'right' }}>
            <span>PFE Revenues <b>{activeInnerPane === 0 ? actual_forecast_details.pfe_revenues : budget_details.pfe_revenues}</b></span>
            <span>PFE Bill Exp <b>{activeInnerPane === 0 ? actual_forecast_details.pfe_billable_expenses : budget_details.pfe_billable_expenses}</b></span>
          </div>
          <Tab onTabChange={onOuterTabChange}
            panes={
              years.map((year, index) => ({
                menuItem: {
                  content: year.toString(),
                  className: activeIndex === index ? 'menu-item-active' : 'menu-item'
                },
                render: () => (
                  <TabPane>
                    {/* bugfix UAT 53: togliere la modale "budget" per i time material nella cost&revenues */}
                    {props.budget.project_type === "Time Material" ?
                      <DetailsPane
                        figures={actual_forecast_figures}
                        storeDetails={actual_forecast_details.details}
                        project_type={props.project_type}
                        year={year}
                        startDate={startDate}
                        endDate={endDate}
                        isReadOnly={isReadOnly}
                        setValues={setValues}
                        theOneRing={theOneRing}
                      />
                      : <Tab
                        onTabChange={onChangeInnerTab}
                        panes={[
                          {
                            menuItem: {
                              content: "Actual & Forecast",
                              className: menuItemClassActual
                            },
                            render: () => (
                              <DetailsPane
                                figures={actual_forecast_figures}
                                storeDetails={actual_forecast_details.details}
                                project_type={props.project_type}
                                year={year}
                                startDate={startDate}
                                endDate={endDate}
                                isReadOnly={isReadOnly}
                                setValues={setValues}
                                theOneRing={theOneRing}
                                isBudget={false}
                                project_status={props.project_status}
                              />
                            )
                          },
                          {
                            menuItem: {
                              content: "Budget",
                              className: menuItemClassBudget
                            },
                            render: () => (
                              <DetailsPane
                                figures={budget_figures}
                                storeDetails={budget_details.details}
                                project_type={props.project_type}
                                year={year}
                                startDate={startDate}
                                endDate={endDate}
                                theOneRing={theOneRing}
                                isReadOnly={true}
                                setValues={() => { }}
                                isBudget={true}
                              />
                            )
                          }
                        ]}
                      />
                    }
                  </TabPane>
                )
              }))
            }
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default NextCostsAndRevenuesT2Modal;
